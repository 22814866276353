import React from "react"
import * as styles from "./text-content.module.scss"

export default function TextContent({ title, span, children }) {
  return (
    <div className={styles.contentWrapper}>
      <div className={styles.contentHeading}>
        <h3>{title}</h3>
        {!!span ? <span>{span}</span> : null}
      </div>
      <div className={styles.contentText}>{children}</div>
    </div>
  )
}

TextContent.defaultProps = {
  title: "",
  span: null,
}
