import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Footer, Header } from "../components"

const Layout = ({ children, site, page }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata?.title || `Freija's Optiek`}
        site={site}
        page={page}
      />
      <main>{children}</main>
      <Footer></Footer>
    </>
  )
}

Layout.defaultProps = {
  site: "optiek",
  page: "/",
}

export default Layout
