import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./navigation-menu.module.scss"

export function NavigationWrapper({ children }) {
  return <div className={styles.navigationWrapper}>{children}</div>
}

export function NavigationMenu({ children, half, hideMobile }) {
  return (
    <div
      className={`${styles.navigationMenu} ${
        hideMobile ? styles.hideMobile : ""
      }`}
      id={half ? "half" : null}
    >
      {children}
    </div>
  )
}

NavigationMenu.defaultProps = {
  half: false,
  hideMobile: false,
}

export function NavigationPane({ children, image, half, hideMobile }) {
  return (
    <div
      className={`${styles.navigationPane} ${
        hideMobile ? styles.hideMobile : ""
      }`}
      id={half ? "half" : null}
    >
      <GatsbyImage
        alt="Navigation image"
        className={styles.navigationImage}
        image={image}
        layout="fullWidth"
      />
      {!!children ? (
        <div className={styles.navigationContent}>{children}</div>
      ) : null}
    </div>
  )
}

NavigationPane.defaultProps = {
  half: false,
  hideMobile: false,
}
