import React, { useEffect } from "react"
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet"
import "leaflet/dist/leaflet.css"
import L from "leaflet"
import icon from "leaflet/dist/images/marker-icon.png"

export default function LeafletMap({ center, zoom, popup }) {
  useEffect(() => {
    let DefaultIcon = L.icon({
      iconUrl: icon,
      iconSize: [25, 41],
	    iconAnchor: [12, 41],
    })
    L.Marker.prototype.options.icon = DefaultIcon
  }, [])

  if (typeof window !== "undefined") {
    return (
      <div>
        <MapContainer
          center={center}
          zoom={zoom}
          scrollWheelZoom={true}
          style={{ height: "400px", zIndex: "0" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          />
          <Marker position={center}>
            <Popup>
              <span>{popup}</span>
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    )
  } else {
    return null
  }
}
