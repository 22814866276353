import React from "react"
import { Button, Column, Columns } from "./"
import * as styles from "./contact-form.module.scss"
import * as textStyles from "./text-content.module.scss"

function submitForm(e) {
  e.preventDefault()
  var form = e.target.parentElement
  var data = new FormData(form)
  var xhr = new XMLHttpRequest()

  var phone = data.get("phone")
  var date = data.get("date")
  var day = new Date(date).getUTCDay()

  if (day === 0 || day === 3 || day === 4) {
    alert("Gelieve een andere dag te kiezen: Ma-Di-Vr-Za.")
  } else {
    if (validatePhone(phone)) {
      xhr.open(form.method, form.action)
      xhr.setRequestHeader("Accept", "application/json")
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return
        if (xhr.status === 200) {
          form.reset()
          alert("Aanvraag verzonden! Uw afspraak wordt telefonisch bevestigd.")
        } else {
          alert("Verzenden mislukt. Gelieve ons telefonisch te contacteren.")
        }
      }
      xhr.send(data)
    } else {
      alert("Gelieve uw naam en een geldig telefoonnummer in te vullen.")
    }
  }
}

function validatePhone(phone) {
  // Replace some commonly used special characters so that e.g. 04/79.87.34.90 still gets recognized as a valid number
  phone = phone.replace(/ /g, "").replace(/\//g, "").replace(/\./g, "")
  // Using regex check if the phone number is either a fixed or mobile line
  var foundmobile = phone.match(/^((\+|00)32\s?|0)4(60|[789]\d)(\s?\d{2}){3}$/g)
  var foundfixed = phone.match(
    /^((\+|00)32\s?|0)(\d\s?\d{3}|\d{2}\s?\d{2})(\s?\d{2}){2}$/g
  )
  // People from Holland must be able to call de schrijfarbeider as well
  var founddutch = phone.match(/^\(?([+]31|0031|0)-?6(\s?|-)([0-9]\s{0,3}){8}$/)
  return !!foundmobile || !!foundfixed || !!founddutch
}

export default function ContactForm() {
  return (
    <form
      method="POST"
      action="https://formspree.io/f/xayaejrv"
      className={styles.contactForm}
    >
      <Columns>
        <Column style={{ paddingRight: "15px" }}>
          <label htmlFor="contact-form-name">Naam</label> <br />
          <input id="contact-form-name" type="text" name="name" />
        </Column>
        <Column>
          <label htmlFor="contact-form-phone">Telefoonnummer</label>
          <input id="contact-form-phone" type="text" name="phone" />
        </Column>
      </Columns>
      <Columns style={{ paddingTop: "10px" }}>
        <Column style={{ paddingRight: "15px" }}>
          <label htmlFor="contact-form-date">
            Datum <small>(Ma-Di-Vr-Za)</small>
          </label>
          <input id="contact-form-date" type="date" name="date" />
        </Column>
        <Column>
          <div className={styles.contactCheckbox}>
            <input id="contact-form-morning" type="checkbox" name="morning" />
            <label htmlFor="contact-form-morning">Voormiddag</label>
          </div>
          <div className={styles.contactCheckbox}>
            <input
              id="contact-form-afternoon"
              type="checkbox"
              name="afternoon"
            />
            <label htmlFor="contact-form-afternoon">Namiddag</label>
          </div>
          <div className={styles.contactCheckbox}>
            <input id="contact-form-evening" type="checkbox" name="evening" />
            <label htmlFor="contact-form-evening">Avond</label>
          </div>
        </Column>
      </Columns>
      <Columns style={{ paddingTop: "10px" }}>
        <Column style={{ paddingRight: "15px" }}>
          <label htmlFor="contact-form-type">Afspraaktype</label> <br />
          <select name="type" id="contact-form-type">
            <option value="Nieuw Hoortoestel">Nieuw Hoortoestel</option>
            <option value="Aanpassing Hoortoestel">
              Aanpassing Hoortoestel
            </option>
            <option value="Gehoorbescherming">Gehoorbescherming</option>
          </select>
        </Column>
        <Column>
          <label htmlFor="contact-form-subject">Onderwerp</label>
          <input id="contact-form-subject" type="text" name="subject" />
        </Column>
      </Columns>
      <label htmlFor="contact-form-message">Bericht</label>
      <textarea name="message" id="contact-form-message" cols="30" rows="1" />
      <p className={textStyles.paragraph}>
        Uw afspraak wordt telefonisch bevestigd.
      </p>
      <Button site="audio" onClick={submitForm}>
        vraag afspraak aan
      </Button>
    </form>
  )
}
