import React from "react"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import * as styles from "./side-by-side.module.scss"

export function SideBySide({ children }) {
  return <div className={styles.sideBySide}>{children}</div>
}

export function SideImage({ image }) {
  const bgImage = convertToBgImage(image)
  return <BackgroundImage className={styles.sideImage} {...bgImage} />
}

export function SideText({ children }) {
  return <div className={styles.sideText}>{children}</div>
}
