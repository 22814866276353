import React from "react"
import * as styles from "./modal.module.scss"

export default function Modal({ state, close, header, children }) {
  if (state) {
    return (
      <>
        <div
          className={styles.modalBackground}
          onClick={close}
          onKeyDown={close}
          role="button"
          tabIndex="0"
          aria-label="Close modal"
        ></div>
        <div className={styles.modalWindow}>
          <div className={styles.modalHeader}>
            <h4>{header}</h4>
            <div
              className={styles.close}
              onClick={close}
              onKeyDown={close}
              role="button"
              tabIndex="0"
            >
              +
            </div>
          </div>
          <div className={styles.modalContent}>{children}</div>
        </div>
      </>
    )
  } else {
    return null
  }
}

Modal.defaultProps = {
  state: false,
  close: () => {
    console.log("No close function implemented for modal")
  },
  header: "404 Not Found",
}
