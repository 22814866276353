import React, { useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Button } from "."
import * as styles from "./header.module.scss"

export default function Header({ page }) {
  var [menuState, setMenuState] = useState(false)
  var links = [
    ["/", "Contact"],
    ["/wieiswim", "Wie is Wim"],
    ["/producten", "Producten"],
    ["/diensten", "Diensten"],
  ]

  return (
    <header className={styles.header} id="audiologie">
      <button
        className={styles.burger}
        id="audiologie"
        onClick={() => setMenuState(!menuState)}
      >
        <div></div>
      </button>
      <div className={styles.logo} id="audiologie">
        <Link to="/">
          <StaticImage
            alt="Logo"
            src="../images/logos/logo-audio.png"
            height={120}
          />
        </Link>
      </div>
      <div className={styles.navbar} id={menuState ? "open" : "closed"}>
        <ul>
          {links.map(link => (
            <li key={link[1]}>
              <Link
                to={link[0]}
                className={styles.navbarItem}
                id={`audio${link[0] === page ? "active" : ""}`}
              >
                {link[1]}
              </Link>
            </li>
          ))}
          <li>
            <a
              href="http://www.freijasoptiek.be"
              className={styles.navbarItem}
              id="button"
              target="_blank"
              rel="noopener noreferrer"
              style={{ width: "100px" }}
            >
              <Button site="audio" onClick={() => {}}>
                Freija's Optiek
              </Button>
            </a>
          </li>
        </ul>
      </div>
    </header>
  )
}

Header.defaultProps = {
  page: "/",
}
