import React from "react"
import * as styles from "./columns.module.scss"

export function Columns({ children, style, responsive }) {
  return (
    <div
      className={styles.columns}
      style={style}
      id={responsive ? "responsive" : null}
    >
      {children}
    </div>
  )
}

Columns.defaultProps = {
  responsive: false,
}

export function Column({ children, style }) {
  return (
    <div className={styles.column} style={style}>
      {children}
    </div>
  )
}
