import React from "react"
import * as styles from "./footer.module.scss"

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div>
        Copyright &copy; Optiek Freija BVBA - Ontwikkeling door{" "}
        <a
          href="https://www.florsanders.be"
          target="_blank"
          rel="noopener noreferrer"
        >
          Flor Sanders
        </a>
      </div>
    </footer>
  )
}
