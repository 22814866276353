import React from "react"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import * as styles from "./hero.module.scss"

export default function Hero({ children, image, site }) {
  const bgImage = convertToBgImage(image)

  return (
    <BackgroundImage {...bgImage}>
      <div className={styles.heroWrapper}>
        <div className={styles.heroText} id={site}>
          {children}
        </div>
      </div>
    </BackgroundImage>
  )
}

Hero.defaultProps = {
  site: "optiek",
  image: null,
}
